@tailwind base;
@tailwind components;
@tailwind utilities;

#app {
  @apply antialiased;
}

.map-container {
  width: 100%;
  height: calc(100vh - 16px);
}

.payment-max-height {
  @apply flex-grow flex flex-col pt-[16px];
}

input:checked + div {
  @apply bg-[#1890FF];
}
input:checked + div svg {
  @apply block;
}

.delivery-date {
  @apply text-center block font-medium text-[18px] leading-[28px] text-[#067F52];
}
